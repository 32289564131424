// header

@if $mode == theme {

	header {
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		background: c(white);
		border-bottom: 1px solid transparent;
		transition-property: border-color;
		transition-duration: get($transition, duration);
		transition-timing-function: get($transition, timing-function);

		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;

			@include container();
		}

		.info {
			display: flex;
			align-items: center;
		}

		.call a {

			@extend button;

			margin-left: 0.5em;
			padding: 0.5em 0.75em;

			svg {
				display: block;
				width: 1.5em;
				height: 1.5em;
				fill: c(white);
			}
		}

		&.is-scrolled-down {
			border-bottom: 1px solid rgba(c(primary), 0.25);
		}

		+ #main {
			padding-top: 4.5em;

			@include media(small) {
				padding-top: 3.75em;
			}
		}
	}

	#wpadminbar {

		@include media(stacked) {
			position: fixed;
		}
	}

	.with-admin-bar header {
		top: 32px;

		@include media(broken) {
			top: 46px;
		}
	}
}
