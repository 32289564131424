// core/heading

@if $mode == theme {

	#{select(heading)} {

		&.is-style-main {
			margin-top: 3rem;
			margin-bottom: 3rem;

			@include media(unstacked) {
				margin-top: 4rem;
				margin-bottom: 4rem;
			}
		}
	}
}

@if $mode == editor {

	#{select(heading)} {

		&.is-style-main {
			margin-top: 3rem !important;
			margin-bottom: 3rem !important;

			@include media(unstacked) {
				margin-top: 4rem !important;
				margin-bottom: 4rem !important;
			}
		}
	}
}
