// layout structure

@if $mode == theme {

	html {
		background: c(primary);
	}

	#main {
		overflow: hidden;
		padding: 0;
		background: c(white);
	}

	// .content > h1:first-child {
	// 	margin: $spacing 0;
	// }
}
