// open popup form

@if $mode == theme {

	.book-consultation {

		a {

			@extend button;

			background: c(secondary);
			border-color: c(secondary);

			&:hover,
			&:focus {
				background: c(secondary, -20);
				border-color: c(secondary, -20);
			}

			span::before {
				content: "";
				margin-left: 0.2em;
			}
		}
	}

	header .book-consultation {
		margin-left: 0.5 * $gap;

		@include media(small) {
			margin-left: 0.5em;

			a {
				flex-direction: column;
				align-items: center;
				font-size: 0.8em;
				line-height: 1.2;

				span::before {
					display: none;
				}
			}
		}
	}
}
