// no results found

@if $mode == theme {

	#main.content .not-found {

		@include empty-state(center);

		@include svg-bg-icon(search, c(base), 3rem) {
			display: block;
			margin: 0 auto;
			opacity: 0.1;
		}
	}
}
