// basic transitions

$transition: () !default;

@if $mode == theme {

	*,
	*::before,
	*::after {
		transition-property: none;
		transition-duration: get($transition, duration, 0.3s);
		transition-timing-function: get($transition, timing-function, ease-out);
	}

	path {
		transition-property: all;
	}

	#{select(a button input)} {
		transition-property: background-color, border-color, color;
	}
}
