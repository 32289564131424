/// Default named container widths and margin
/// @group helper

$container: (
  normal: 60rem,
  wide: 80rem,
  narrow: 40rem,
  margin: 1rem
) !default;

/// Default named container
/// @group helper

$container-default: normal !default;

@use "sass:map";
@use "../utility/same-units" as *;

/// Create a width-limited container with side-margin on smaller screens
/// @param {number} $width [null] The width-limit
/// @param {number} $margin [null] The margin size (on smaller screens)
/// @group helper

@mixin container($width: null, $margin: null) {
  $width: if(
    $width and map.has-key($container, $width),
    map.get($container, $width),
    if(
      $width,
      $width,
      map.get($container, $container-default)
    )
  );
  $margin: if($margin, $margin, map.get($container, margin));

  max-width: $width;
  margin-right: $margin;
  margin-left: $margin;

  $min-width: if(same-units($width, $margin), $width + 2 * $margin, calc($width + 2 * $margin));

  @media only screen and (min-width: #{$min-width}) {
    margin-right: auto;
    margin-left: auto;
  }
}
