// skip link for accessiblity

@if $mode == theme {

	.skip-link {
		position: fixed !important;
		transition-property: clip, clip-path, height, opacity, width;

		&:focus {
			right: auto;
			top: 0.5em;
			left: 0.5em;
			z-index: 100000;
		}
	}
}
