// footer

@if $mode == theme {

	footer {
		background: c(primary);

		@include color-change(c(white), c(primary, -80));

		> div {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding-top: 2em;
			padding-bottom: 2em;

			@include container();

			@include media(broken) {
				flex-wrap: wrap;
				text-align: center;

				> * {
					flex-basis: 100%;

					+ * {
						margin-top: $spacing;
					}
				}
			}
		}

		.menu {

			@include simple-menu();

			.has-linkedin-icon a {
				font-size: 0;
				transition-property: opacity;

				@include svg-bg-icon(linkedin, c(white), 1.5rem);

				&:hover,
				&:focus {
					opacity: 0.8;
				}
			}
		}
	}
}
