// list posts

@if $mode == theme {

	.post-list {
		display: grid;
		grid-gap: $gap;
		max-width: get($container, wide) !important;

		@include media(unstacked) {
			grid-template-columns: 1fr 1fr 1fr;
		}
	}
}
