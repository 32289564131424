// password protected page

$svg-icons: merge($svg-icons, (
	lock: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>'
));

@if $mode == theme {

	#main.content.has-password-protection > div {

		@include empty-state(center);

		h1 {

			@include svg-bg-icon(lock, c(base), 3rem) {
				display: block;
				margin: 0 auto (0.5 * $spacing);
				opacity: 0.1;
			}
		}

		.post-password-form {

			#{select(input button)} {
				margin: 0;
			}

			p,
			label {
				display: flex;
				justify-content: center;
				align-items: center;

				@include media(stacked) {
					flex-direction: column;

					[type="password"] {
						margin-top: 0.5 * $spacing;
					}

					[type="submit"] {
						margin-top: $spacing;
					}
				}

				@include media(unstacked) {

					[type="password"],
					[type="submit"] {
						margin-left: $gap;
					}
				}
			}
		}
	}
}
