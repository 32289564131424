// common, simple menu styles

@use "../../node_modules/sass-styler/styler" as *;
@use "../_config" as *;

@mixin simple-menu() {

	a {
		display: inline-flex;
		margin-left: -0.5 * $pad;
		margin-right: -0.5 * $pad;
		padding: 0.5 * $pad;
	}

	@include media(stacked) {

		@include unstyled-list();
	}

	@include media(unstacked) {

		@include horizontal-list($pad, center, center);
	}
}
