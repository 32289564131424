// empty state

@use "../../node_modules/sass-styler/styler" as *;
@use "../_config" as *;

@mixin empty-state($text-align: null) {
	margin-top: 2 * $spacing;
	margin-bottom: 2 * $spacing;
	padding: 2 * $pad;
	border: 3px dashed c(grey);
	text-align: $text-align;

	* + * {
		margin-top: $spacing;
	}
}
