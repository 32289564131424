// stars-input

@if $mode == theme or $mode == editor {

	.blob-stars {
		display: flex;
		line-height: 1;

		.has-text-align-center &,
		&.has-text-align-center,
		&.is-text-align-center {
			justify-content: center;
		}
	}

	.blob-star {

		@include svg-bg-icon(star-empty, c(primary), 1rem);

		&.is-active {

			@include svg-bg-icon(star, c(primary));
		}
	}
}
