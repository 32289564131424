// default listing view of post

@if $mode == theme {

	.excerpt {

		@include text-variation-small();
		@include cover-link("> a");

		> a {
			font-size: 0;
			opacity: 0;
		}

		.meta {
			color: rgba(c(base), 0.5);

			> *:not(:last-child) {
				margin-right: 0.25em;
				padding-right: 0.25em;
				border-right: 1px solid c(grey);
			}
		}

		&__header {
			background: rgba(c(base), 0.1);

			@include responsive-ratio();

			img {
				object-fit: cover;
				object-position: 50% 50%;

				@include position(0, 0, 0, 0, absolute, 100%, 100%);
			}
		}

		&__main {

			&,
			> * + * {
				margin-top: 0.5 * $spacing;
			}
		}
	}
}
