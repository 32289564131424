// main navigation in the header

@if $mode == theme {

	.main-navigation {

		@include media(broken) {
			order: 2;
			margin-left: 1em;
		}

		&__toggle {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			margin-right: -0.5 * $pad;
			margin-left: -0.5 * $pad;
			padding: 0.5 * $pad;

			@include svg-bg-icon(menu, c(base), 1.5em);

			@at-root .has-main-navigation-open & {

				@include svg-bg-icon(close, c(primary));
			}

			@include media(unbroken) {
				display: none;
			}
		}

		&__menu {

			a {
				display: inline-flex;
				align-items: center;
				padding: 0 (0.5 * $pad);
				font-family: get($styles, heading font-family);
				font-weight: get($styles, heading font-weight);
				text-transform: uppercase;
				color: c(base);

				&:hover,
				&:focus {
					color: c(primary);
				}
			}

			ul {
				display: none;
			}

			@include media(broken) {
				display: none;
				overflow: hidden;
				width: 100%;
				padding: 0.5 * $pad get($container, margin);
				background: c(primary);
				text-align: center;

				@include unstyled-list();
				@include color-change();
				@include position(100%, 0);

				a {
					padding: 0.5em;

					&:hover,
					&:focus {
						color: c(white, 10);
					}
				}

				@at-root .has-main-navigation-open & {
					display: block;
				}
			}

			@include media(unbroken) {
				margin-bottom: -2px;

				@include horizontal-list(0);

				> li > a {
					position: relative;
					padding-top: 0.5em;
					padding-bottom: 0.5em;
					line-height: 1.5em;
					min-height: 4.5rem;
					border-top: 1px solid transparent;
					border-bottom: 1px solid transparent;
				}

				> li:hover > a {
					border-bottom-color: c(primary);
				}
			}

			@include media(unbroken small) {

				> li > a {

					font-size: responsive-font(12px, 782px, 16px, 1099px);
					min-height: 3.75rem;
					padding: 0.5em;
				}
			}
		}
	}
}
