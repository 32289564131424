// core/buttons

@mixin block-core-buttons {
	margin-bottom: 0;
}

@mixin block-core-button {

	&.aligncenter {
		text-align: center;
	}

	&.alignright {
		text-align: right;
	}
}

@mixin block-core-button-link {
	display: inline-block;
	text-decoration: none;
	overflow-wrap: break-word;
	cursor: pointer;
	text-align: center;
	border-radius: 0;
	font-size: inherit;

	@include declare-map(get($styles, button));

	&.no-border-radius {
		border-radius: 0;
	}
}

@if $mode == theme or $mode == editor {

	.wp-block-buttons {
		margin-right: -4px;
		margin-left: -4px;

		@include block-core-buttons();

		.wp-block-button {
			margin-top: 0;
			margin-bottom: 0;
		}
	}

	.wp-block-button {

		@include block-core-button();
	}

	.wp-block-button__link {

		@include block-core-button-link();
	}

	.wp-block-button.is-style-ghost,
	.wp-block-button.is-style-blue-tile,
	.wp-block-button.is-style-purple-tile,
	.wp-block-button.is-style-grey-tile {
		width: 100%;
		min-height: 8em;

		.wp-block-button__link {
			width: 100%;
			min-height: 10em;
			justify-content: center;
			align-items: center;
			font-size: 1.2em;
		}
	}

	.wp-block-button.is-style-purple-tile .wp-block-button__link {
		background: c(secondary);
		border-color: c(secondary);

		&:hover,
		&:focus {
			background: c(secondary, -20);
			border-color: c(secondary, -20);
		}
	}

	.wp-block-button.is-style-grey-tile .wp-block-button__link {
		background: c(tertiary);
		border-color: c(tertiary);

		&:hover,
		&:focus {
			background: c(tertiary, -20);
			border-color: c(tertiary, -20);
		}
	}

	.wp-block-button.is-style-ghost .wp-block-button__link {
		background: transparent;
		color: c(primary);

		&:hover,
		&:focus {
			background: transparent;
			color: c(primary, -20);
		}
	}
}

@if $mode == editor {

	.wp-block-button {
		margin-top: 0;
		margin-bottom: 0;
	}

	.wp-block-buttons [data-type="core/button"] {
		margin-top: 0;
	}

	.editor-styles-wrapper .block-editor-block-list__block[data-type="core/button"] {
		margin: 0;
	}

	.wp-block-buttons .block-editor-block-list__layout {
		display: flex;
		flex-wrap: wrap;
	}

	.wp-block-buttons .block-list-appender {
		display: inline-flex;
	}

	[data-type="core/buttons"]:not(.is-selected) .block-list-appender {
		display: none !important;
	}

	[data-type="core/buttons"][data-align="center"] .block-editor-block-list__layout {
		justify-content: center;
	}

	[data-type="core/buttons"][data-align="right"] .block-editor-block-list__layout {
		justify-content: flex-end;
	}

	.block-editor-button-block-appender {
		padding: get($styles, button padding);
	}

	.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/buttons"][data-align="right"] > .is-block-content {
		float: none;
		margin-left: inherit;
	}

	.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/buttons"][data-align="left"] > .is-block-content {
		float: none;
		margin-right: inherit;
	}

	.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/buttons"][data-align="right"],
	.block-editor-block-list__layout .block-editor-block-list__block[data-type="core/buttons"][data-align="left"] {
		height: auto;
	}

	.wp-block[data-type="core/buttons"][data-align="left"] > .is-block-content {
		margin-right: inherit !important;
	}

	.wp-block[data-type="core/buttons"][data-align="right"] > .is-block-content {
		margin-left: inherit !important;
	}

	.wp-block[data-type="core/buttons"][data-align="right"] > .is-block-content,
	.wp-block[data-type="core/buttons"][data-align="left"] > .is-block-content {
		max-width: none;
	}

	.wp-block-buttons .wp-block.block-editor-block-list__block[data-type="core/button"] {

		@include select-first-out-of(2) {
			flex-grow: 1;
		}
	}
}
