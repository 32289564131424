@use "script-direction" as *;

/// Make a list horizontal
/// @param {Number} $gap [null] The gap between items
/// @param {String} $justify-content [null] The justify-content value
/// @param {String} $align-items [null] The align-items value
/// @param {String} $flex-wrap [null] The flex-wrap value
/// @group helper

@mixin horizontal-list($gap: null, $justify-content: null, $align-items: null, $flex-wrap: null) {
  display: flex;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
  list-style: none;
  padding-#{script-direction()}: 0;

  > * + * {
    margin-#{script-direction()}: $gap;
  }
}
