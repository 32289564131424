// core/paragraph

@if $mode == theme {

	p.is-style-lead {
		font-size: 1.2em;
		font-family: get($styles, heading font-family);
		font-weight: get($styles, heading font-weight);

		@include media(unstacked) {
			font-size: 1.4em;
		}
	}

	p.is-style-subtitle {
		margin-top: 3rem;
		margin-bottom: 3rem;
		font-family: get($styles, heading font-family);
		font-weight: get($styles, heading font-weight);
		text-transform: uppercase;

		@include media(unstacked) {
			margin-top: 4rem;
			margin-bottom: 4rem;
		}
	}

	#{select(heading)} {

		+ p.is-style-subtitle {
			margin-top: -3rem;

			@include media(unstacked) {
				margin-top: -4rem;
			}
		}
	}
}

@if $mode == editor {

	p.is-style-lead {
		font-size: 1.2em;
		font-family: get($styles, heading font-family);
		font-weight: get($styles, heading font-weight);

		@include media(unstacked) {
			font-size: 1.4em;
		}
	}

	p.is-style-subtitle {
		margin-top: 3rem !important;
		margin-bottom: 3rem !important;
		font-family: get($styles, heading font-family);
		font-weight: get($styles, heading font-weight);
		text-transform: uppercase;

		@include media(unstacked) {
			margin-top: 4rem !important;
			margin-bottom: 4rem !important;
		}
	}

	.wp-block[data-type="core/heading"] {

		+ .wp-block[data-type="core/paragraph"] p.is-style-subtitle {
			margin-top: -3rem !important;

			@include media(unstacked) {
				margin-top: -4rem !important;
			}
		}
	}
}
