// 404 page

$svg-icons: merge($svg-icons, (
	error: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"/></svg>'
));

@if $mode == theme {

	#main.content.is-error > div {

		@include empty-state(center);

		h1 {

			@include svg-bg-icon(error, c(base), 3rem) {
				display: block;
				margin: 0 auto (0.5 * $spacing);
				opacity: 0.1;
			}
		}
	}
}
